import styles from './LoadingOverlay.module.scss'
import React from 'react'
import Loading from '../Loading/Loading'
import classNames from 'classnames'

type Props = {
  background?: boolean
}

const LoadingOverlay = (props: Props) => {
  return (
    <div className={classNames(styles.component, { [styles.background]: props.background })}>
      <Loading />
    </div>
  )
}

export default LoadingOverlay