import classNames from 'classnames'
import styles from './Loading.module.scss'
import React from 'react'

type Props = {
  small?: boolean
}

const Loading = (props: Props) => {
  return (
    <div className={classNames(styles.component, { [styles.small]: props?.small })} data-testid="loading">
      <div className={styles.spinner} />
    </div>
  )
}

export default Loading