import styles from '@/styles/Login.module.scss'
import BaseLayout from '@/layout/BaseLayout/BaseLayout'
import Head from 'next/head'
import React from 'react'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import DefaultInput from '@/components/inputs/DefaultInput/DefaultInput'
import { useTranslation } from 'next-i18next'
import Button from '@/components/buttons/Button/Button'
import classNames from 'classnames'
import Form from '@/components/common/Form/Form'
import { useRouter } from 'next/router'
import { useLoginMutation } from '@/api/login'
import LoadingOverlay from '@/components/common/LoadingOverlay/LoadingOverlay'
import { getPageTitle } from '@/utils/helpers'
import Link from 'next/link'
// import { cartApi } from '@/api/cart'
import { useAppDispatch } from '@/utils/hooks/useAppDispatch'
import useAnalyticsManager from '@/utils/hooks/useAnalyticsManager'

type Props = {}

const Login = (props: Props) => {
  const { t } = useTranslation(['login', 'common'])
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [rememberMe, setRememberMe] = React.useState(false)
  const router = useRouter()
  const [sendLogin, result] = useLoginMutation()
  const [loginError, setLoginError] = React.useState(false)
  const dispatch = useAppDispatch()
  const { login } = useAnalyticsManager()

  const onChangeHandler = (value: string, name: string) => {
    switch (name) {
      case 'email':
        setEmail(value)
        break
      case 'password':
        setPassword(value)
        break
      case 'rememberMe':
        setRememberMe(!!value)
        break
    }
  }

  const onSubmitHandler = () => {
    if (!email || !password) {
      return
    }

    sendLogin({
      email,
      password,
      rememberMe,
    }).then((res: any) => {
      if (res?.error) {
        setLoginError(true)
        return
      }

      router.push('/')
      login(res.data)

      // dispatch(cartApi.util.invalidateTags(['Cart']))
    }).catch((err) => {
      console.error(err, 'error')
    })
  }

  const onClickRegister = () => {
    router.push('/register')
  }

  return (
    <BaseLayout>
      <Head>
        <title>{getPageTitle(t('login:login'))}</title>
      </Head>
      <main className={classNames(styles.login, 'row')}>
        <div className="col-xs-12 col-md-4">
          <h4>{t('login')}</h4>
          <Form onSubmit={onSubmitHandler}>
            {result.isLoading && <LoadingOverlay />}
            <label>{t('common:email')}</label>
            <DefaultInput
              type='email'
              name='email'
              value={email}
              onChange={onChangeHandler}
            />
            <label>{t('password')}</label>
            <DefaultInput
              type='password'
              name='password'
              value={password}
              onChange={onChangeHandler}
            />
            {loginError && (
              <div className={styles.error}>
                {t('invalidLogin')}
              </div>
            )}
            <Button
              type={'submit'}
              label={t('loginButton')}
              large={true}
              rounded={true}
            />
          </Form>
          <div className={styles.resetPassword}>
            <Link href="/resetpassword">
              {t('login:resetPassword')}
            </Link>
          </div>

        </div>
        <div className="col-xs-12 col-md-offset-2 col-md-6">
          <h4>{t('newCustomer')}</h4>
          <p>
            {t('newCustomerInfo')}
          </p>
          <Button
            label={t('register')}
            large={true}
            lightDark={true}
            rounded={true}
            onClick={onClickRegister}
            className={styles.registerButton}
          />
        </div>
      </main>
    </BaseLayout>
  )
}

export default Login

export const getStaticProps: GetStaticProps<Props> = async ({
  locale,
}) => ({
  props: {
    ...(await serverSideTranslations(locale ?? 'en', [
      'common',
      'login',
      'footer',
    ])),
  },
})