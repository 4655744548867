import styles from './Form.module.scss'
import classNames from 'classnames'
import React from 'react'

type Props = {
  children: React.ReactNode,
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  className?: string,
}

const Form = (props: Props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSubmit(e)
  }

  return (
    <form
      onSubmit={onSubmit}
      className={classNames(styles.component, props.className)}
    >
      {props.children}
    </form>
  )
}

export default Form