import styles from './DefaultInput.module.scss'
import React, { ReactElement, useEffect } from 'react'
import SearchIcon from '@/assets/svg/search.svg'
import classNames from 'classnames'
import { RegisterOptions } from 'react-hook-form/dist/types'

type Props = {
  name: string,
  value?: string,
  placeholder?: string,
  label?: string | null | undefined,
  onChange?: (value: string, name: string) => void
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (value: string, name: string) => void
  searchIcon: boolean,
  small?: boolean,
  tiny?: boolean,
  center?: boolean,
  type: 'text' | 'number' | 'email' | 'password',
  min?: number,
  maxWidth?: string,
  disabled?: boolean,
  inputStyle: 'border' | 'background' | 'lightBackground' | 'bottomBorder',
  registerOptions?: RegisterOptions,
  error?: string,
}

const DefaultInput = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.value || '')

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (props.onChangeEvent !== undefined) {
      props.onChangeEvent(event)
    }

    if (props.onChange === undefined) {
      setValue(value)
      return
    }

    props.onChange(value, name)
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (props.onBlur === undefined)
      return

    props.onBlur(value, name)
  }

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  const classes = classNames(
    styles.component,
    {
      [styles.small]: props.small,
      [styles.tiny]: props.tiny,
      [styles.hasIcon]: props.searchIcon,
      [styles.center]: props.center,
      [styles.border]: props.inputStyle === 'border',
      [styles.background]: props.inputStyle === 'background',
      [styles.bottomBorder]: props.inputStyle === 'bottomBorder',
      [styles.lightBackground]: props.inputStyle === 'lightBackground',
      [styles.error]: props.error !== undefined && props.error !== '',
    }
  )

  const customStyle = {
    maxWidth: props.maxWidth,
  }

  return (
    <div className={classes} style={customStyle}>
      {props.searchIcon && (
        <SearchIcon />
      )}
      {props.label && (
        <label htmlFor={props.name}>{props.label}</label>
      )}
      <input
        type={props.type}
        name={props.name}
        value={value}
        min={props.min}
        placeholder={props.placeholder}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={props.disabled}
      />
      {props.error && (
        <div className={styles.errorText}>
          {props.error}
        </div>
      )}
    </div>
  )
}

DefaultInput.defaultProps = {
  searchIcon: false,
  type: 'text',
  inputStyle: 'border',
}

export default DefaultInput